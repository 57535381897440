.annotationTitle {
  width: 302px;
  max-height: 424px;
  padding: 16px;
  border: 1px solid var(--brand-borderColor);
  border-radius: 6px;
  background-color: var(--brand-primaryBackgroundColor);
  overflow: scroll;

  &.mobile {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .active {
    margin-bottom: 16px;
  }
}

// lines (marks, leaves)
.textBold {
  /* stylelint-disable-next-line font-weight-notation */
  font-weight: bolder;
}

.textStrikethrough {
  text-decoration: line-through;

  * {
    text-decoration: inherit;
  }
}

.textItalic {
  font-style: italic;
}

.textUnderline {
  text-decoration: underline;

  * {
    text-decoration: inherit;
  }
}

.textStrikethrough.textUnderline {
  text-decoration: underline line-through;
}

// END of lines (marks, leaves)

// Blocks (elements)
.numberedList,
.bulletedList {
  display: flex;
  flex-direction: column;
  gap: var(--list-spacing);
  margin-bottom: 0;
  padding-left: 25px;
  font-weight: var(--list-fontWeight);
  font-style: var(--list-fontStyle);
  font-size: var(--list-fontSize);
  font-family: var(--list-fontFamily);
  line-height: var(--list-lineHeight);
  color: var(--list-color);

  .listItem {
    padding-left: var(--list-gap);
  }
}

.caption {
  font-weight: var(--caption-fontWeight);
  font-style: var(--caption-fontStyle);
  font-size: var(--caption-fontSize);
  font-family: var(--caption-fontFamily);
  line-height: var(--caption-lineHeight);
  color: var(--caption-color);
}

.elementBlockquote {
  margin-bottom: 0;
  margin-left: 5px;
  padding-left: 10px;
  border-left: 2px solid var(--quote-borderLeftColor);
  font-weight: var(--quote-fontWeight);
  font-style: var(--quote-fontStyle);
  font-size: var(--quote-fontSize);
  font-family: var(--quote-fontFamily);
  line-height: var(--quote-lineHeight);
  color: var(--quote-color);
}

.heading1,
.heading2,
.heading3,
.heading4 {
  padding: 0;
}

.heading4 {
  margin: 0;
  font-weight: var(--h4-fontWeight);
  font-style: var(--h4-fontStyle);
  font-size: var(--h4-fontSize);
  font-family: var(--h4-fontFamily);
  line-height: var(--h4-lineHeight);
  color: var(--h4-color);
}

.heading3 {
  margin: 0;
  font-weight: var(--h3-fontWeight);
  font-style: var(--h3-fontStyle);
  font-size: var(--h3-fontSize);
  font-family: var(--h3-fontFamily);
  line-height: var(--h3-lineHeight);
  color: var(--h3-color);
}

.heading2 {
  margin: 0;
  font-weight: var(--h2-fontWeight);
  font-style: var(--h2-fontStyle);
  font-size: var(--h2-fontSize);
  font-family: var(--h2-fontFamily);
  line-height: var(--h2-lineHeight);
  color: var(--h2-color);
}

.heading1 {
  margin: 0;
  font-weight: var(--h1-fontWeight);
  font-style: var(--h1-fontStyle);
  font-size: var(--h1-fontSize);
  font-family: var(--h1-fontFamily);
  line-height: var(--h1-lineHeight);
  color: var(--h1-color);
}

.link {
  color: var(--link-color);

  &:hover {
    /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
    color: var(--link-color);
    color: var(--link-hoverColor);
  }

  span {
    text-decoration: var(--link-textDecoration);
  }
}

.code {
  font-family: 'IBM Plex Mono', monospace;

  .pseudoSelection {
    display: inline;
  }
}

.annotation {
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -2px;
    left: 0;
    border-color: inherit;
    border-bottom: 1px dashed;
    pointer-events: none;
  }

  &:hover {
    &:after {
      opacity: 0.5;
    }
  }
}

// END of Blocks (elements)
