.root {
  display: block;
  min-height: 56px;
  padding: 16px;
  word-break: break-word;
}

.linkIcon {
  margin-right: 8px;
  font-size: 24px;
  line-height: 24px;
  color: var(--brand-linkColor);
}

.icon {
  width: 1em;
  height: 1em;
  margin: 0;
  background-size: contain;
}

.preview {
  display: flex;
  width: 100%;
  margin-top: 12px;
  word-break: break-word;
  overflow: hidden;

  &.mobile {
    flex-direction: column-reverse;
  }
}

.previewInfo {
  display: flex;
  flex: 3 75%;
  flex-direction: column;
  gap: 12px;
  padding: 24px;
}

.previewImg {
  position: relative;
  min-height: 132px;
  flex: 1 25%;
  margin: 3px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.placeholder {
  font-size: 14px;
  color: var(--color-placeholder);

  &:hover {
    color: var(--color-placeholder);
  }
}

.emptyTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.description {
  white-space: pre-wrap;
}
