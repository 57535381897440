.root {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.active {
  cursor: pointer;
}

.headerTitle {
  display: -webkit-box;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
