.default {
  display: flex;
  align-items: center;

  .defaultName {
    color: var(--color-text-main);

    &.error {
      color: var(--color-input-error);
    }

    &.interactive {
      cursor: pointer;
    }
  }

  &.xmini {
    .defaultName {
      margin-left: 4px;
    }
  }

  &.mini {
    .defaultName {
      margin-left: 4px;
      font-size: 13px;
    }
  }

  &.medium {
    .defaultName {
      margin-left: 8px;
    }
  }

  &.large {
    .defaultName {
      margin-left: 8px;
    }
  }

  .empty {
    color: var(--color-text-secondary);
  }

  .disabled {
    color: var(--color-placeholder);
  }
}

.name {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.mini {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: var(--color-text-secondary);
  }
}

.full {
  display: flex;

  &.info {
    cursor: default;
  }

  .fullRight {
    margin-left: 8px;

    .fullName {
      display: -webkit-box;
      padding: 3px 0 3px 1px;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .fullRole {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: var(--color-text-secondary);
    }
  }
}

.mention {
  display: inline-block;
  margin: 1px;
  padding: 1px 4px;
  border-radius: 6px;
  background-color: var(--color-green-alpha);
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--color-green);
}

.removed {
  background-color: var(--color-disabled);
  color: var(--color-text-secondary);
}
