.root {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--color-text-secondary);

  &:hover {
    color: var(--color-text-main);
  }
}

.grow {
  padding: 12px;
}

.text.grow {
  flex-grow: 1;
  justify-content: center;
  font-size: 13px;
}

:not(.default).tabLeft {
  justify-content: flex-start;
}

:not(.default).tabRight {
  justify-content: flex-end;
}

:not(.default).tabCenter {
  justify-content: center;
}

.text,
.clear {
  flex-grow: 0;
  justify-content: flex-start;
  margin: 0 12px;
  padding: 14px 0;
}

.clear {
  &:first-child {
    margin-left: 0;
  }
}

.text {
  color: var(--color-text-main);

  &:hover {
    color: var(--color-primary-default);
  }
}

.default:first-child {
  margin: 0 12px 0 16px;
}

.mini {
  margin: 0 6px;
  padding: 12px 0;

  &.default:first-child {
    margin: 0 6px 0 16px;
  }
}

.medium {
  margin: 0 8px;
  padding: 14px 0;

  &.default:first-child {
    margin: 0 8px 0 16px;
  }
}

.large {
  margin: 0 12px;
  padding: 18px 0;

  &.default:first-child {
    margin: 0 12px 0 16px;
  }
}

.active {
  color: var(var(--color-text-main));

  &.text {
    color: var(--color-primary-default);
  }
}

.horizontal {
  justify-content: flex-start;
  margin: 12px 0;
  padding: 0 12px;
  padding-left: 12px;
  font-weight: 500;
  line-height: 24px;
  color: var(--color-text-main);

  &:first-of-type {
    margin-top: 16px;
  }

  &:last-of-type {
    margin-bottom: 16px;
  }

  &.default {
    margin: 12px 0;

    &:first-child {
      margin: 12px 0;
      margin-left: 0;
    }
  }

  &:hover {
    color: var(--color-primary-default);
  }

  &.active {
    color: var(--color-primary-default);
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  color: var(--color-text-secondary);

  &:hover {
    color: var(--color-text-secondary);
  }
}
