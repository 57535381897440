.root {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 8px;
  background: var(--color-background-main);
}

.default {
  margin: 20px 48px;
}

.clear {
  margin: 0 48px;
}

.sub {
  margin: 20px 48px 0;
}

.project {
  margin: 20px 72px;
}

.between {
  justify-content: space-between;
}

.center {
  justify-content: center;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.horizontalFree {
  margin-right: 0;
  margin-left: 0;
}
