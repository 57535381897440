.root {
  position: relative;
  display: flex;
  flex-direction: column;
  transition: background-color 0.2s;

  &.hovered:hover {
    border: 1px solid var(--color-input-focus);
    outline: 1px solid var(--color-input-focus);
  }

  &.selected {
    border: 1px solid var(--color-input-focus);
    outline: 1px solid var(--color-input-focus);
  }

  &.hiddenActions {
    &:hover {
      border: 1px solid var(--color-input-focus);
      outline: 1px solid var(--color-input-focus);

      .actions {
        display: block;
        z-index: 1;
      }
    }
  }

  &.xmini {
    padding: 4px;
  }

  &.mini {
    padding: 16px;
  }

  &.medium,
  &.large {
    padding: 20px;
  }

  &.free {
    padding: 0;
    overflow: hidden;
  }

  &.shadow {
    border-color: transparent;
    box-shadow: var(--box-shadow-default);
  }

  &.focused {
    border: 2px solid var(--color-input-focus);
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.actions {
  position: absolute;
  top: 15px;
  right: 15px;

  &.hiddenActions {
    display: none;
  }
}

.active {
  cursor: pointer;
}

.fluid {
  width: 100%;
  height: 100%;
  min-width: unset;
}

.default {
  border: 1px solid var(--color-delimiter-main);
  border-radius: var(--border-radius-main);
}

.primary {
  border-radius: calc(var(--border-radius-main));
  background: var(--color-primary-active);
}

.badge {
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 0 4px;
  border-radius: 6px;
  background-color: var(--color-green-alpha);
  font-size: 13px;
  color: var(--color-green);
}
