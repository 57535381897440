.modalFooter {
  display: flex;
  min-height: 36px;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  color: var(--color-text-secondary);
}

.leftSide {
  display: flex;
  align-items: center;
}

.close {
  display: flex;
  padding-left: 12px;
}

.actionsContainer {
  display: flex;
  align-items: center;
}

.selected {
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-left: 1px solid var(--color-delimiter-main);
}

.selectedTitle {
  margin: 0 12px 0 4px;
  font-size: 13px;
}

.remove {
  display: flex;
  align-items: center;
  padding-left: 12px;
  border-left: 1px solid var(--color-delimiter-main);
  font-size: 20px;
}

.buttons {
  display: flex;
  gap: 9px;
}
