.root {
  position: absolute;
  display: flex;
  min-width: 10px;
  max-width: 100%;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  line-height: 18px;
  pointer-events: all;
  z-index: 10000;
}

.content {
  display: flex;
  height: 100%;
  min-height: 100%;
  max-width: 100%;
  flex-grow: 1;
  flex-direction: column;
  padding: 8px 0;
  border-radius: 6px;
  box-shadow: var(--box-shadow-default);
  background: var(--color-background-main);
  overflow: auto;
  overflow-y: auto;
}

.default {
  padding: 16px;
}

.clear {
  padding: 0;
}

.tooltip {
  padding: 4px 8px;
  background-color: var(--color-background-dark);
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  word-break: break-word;
  color: var(--color-background-secondary);
}

.dark {
  background-color: var(--color-background-dark);
  color: var(--color-white);
}

.light {
  background-color: var(--color-background-main);
  color: var(--color-text-main);
}

.warning {
  background-color: var(--color-orange);
  color: var(--color-white);
}

.purple {
  background-color: var(--color-violet);
  color: var(--color-white);
}

.portalDropDown {
  position: fixed;
  pointer-events: none;
  z-index: 10001;
  inset: 0;
}

.disabled {
  cursor: not-allowed;
}
