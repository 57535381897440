.root {
  position: absolute;
  max-width: 100%;
  max-height: 100%;
  white-space: pre-wrap;
  z-index: 100;

  &.mobile {
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
}
