.single {
  display: flex;
  max-width: 100%;
  align-items: center;
  padding: 0;
  font-weight: 400;
  line-height: 18px;
  transition: var(--transition-duration) ease-in-out;
  color: var(--color-text-main);

  &.xmini {
    font-size: 12px;

    &.default {
      padding-left: 6px;
    }
  }

  &.mini {
    font-size: 13px;

    &.default {
      padding-left: 6px;
    }
  }

  &.medium {
    font-size: 13px;

    &.default {
      padding-left: 5px;
    }
  }

  &.large {
    font-size: 13px;

    &.default {
      padding-left: 7px;
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: var(--color-text-secondary);
  }
}

.tag {
  display: flex;
  max-width: 100%;
  align-items: center;
  gap: 4px;
  padding: 0 4px;
  border: 1px solid transparent;
  border-radius: var(--border-radius-inner);
  box-shadow: var(--box-shadow-small);
  background-color: var(--color-background-main);
  transition: var(--transition-duration) ease-in-out;

  &.xmini {
    height: 18px;
    font-size: 11px;
    line-height: 16px;
  }

  &.mini {
    height: 24px;
    font-size: 12px;
    line-height: 18px;
  }

  &.medium {
    height: 26px;
    font-size: 12px;
    line-height: 18px;
  }

  &.large {
    height: 30px;
    font-size: 12px;
    line-height: 18px;
  }

  &.error {
    color: var(--color-input-error);
  }

  &.disabled {
    background: var(--color-disabled);
    cursor: not-allowed;
    color: var(--color-text-secondary);
  }
}

.label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
