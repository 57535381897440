@use 'sass:color';
@import './theme.dark';

:root {
  // PRIMARY
  --color-primary-default: var(--color-primary-500);
  --color-primary-active: var(--color-primary-600);
  // ???
  --color-primary-hover: var(--color-primary-100);
  --color-primary-selected: var(--color-primary-200);
  // SECONDARY
  --color-secondary-default: var(--color-secondary-500);
  // BACKGROUND
  --color-background-main: #fff;
  --color-background-secondary: var(--color-neutral-100);
  --color-background-dark: var(--color-neutral-600);
  // DELIMITER
  --color-delimiter-main: var(--color-neutral-200);
  --color-delimiter-secondary: var(--color-neutral-300);
  --color-delimiter-dark: #3f3f3f; // TODO: to palette
  // TEXT
  --color-text-main: var(--color-neutral-600);
  --color-text-secondary: var(--color-neutral-500);
  // PLACEHOLDER
  --color-placeholder: var(--color-neutral-400);
  // DISABLED
  --color-disabled: var(--color-neutral-300);
  // INPUT
  --color-input-focus: var(--color-primary-400);
  --color-input-error: #f00;

  // SHADOW
  --box-shadow-default: 0 2px 10px 0 #0000001a, 0 0 2px 0 #0003;
  --box-shadow-small: 0px 0px 3px 0px #75757559;

  // EDITOR

  --opacity-hover-preview: color-mix(in sRGB, var(--color-primary-500) 5%, transparent);
  --opacity-hover-editor: color-mix(in sRGB, var(--color-secondary-500) 5%, transparent);
  --opacity-main: color-mix(in sRGB, var(--color-neutral-600) 30%, transparent);

  // LINK
  --color-link: var(--color-neutral-600);
  // --color-link-hover: var(--color-neutral-600);

  // FEEDBACK
  --color-error: #960f0f;
  --color-warning: #f2994a;
  --color-success: #01b075;

  // SYSTEM
  --color-white: #fff;
  --color-black: #000;
  --color-red: #ef5b3d;
  --color-red-alpha: #{color.adjust(#ef5b3d, $alpha: -0.85)};
  --color-orange: #f2994a;
  --color-orange-alpha: #{color.adjust(#f2994a, $alpha: -0.9)};
  --color-yellow: #f1bf33;
  --color-yellow-alpha: #{color.adjust(#f1bf33, $alpha: -0.9)};
  --color-yellow-alpha-bg: #{color.adjust(#f1bf33, $alpha: -0.75)};
  --color-green: #36aa55;
  --color-green-alpha: #{color.adjust(#36aa55, $alpha: -0.9)};
  --color-blue: #3d8bf4;
  --color-blue-alpha: #{color.adjust(#3d8bf4, $alpha: -0.85)};
  --color-violet: #736eef;
  --color-violet-alpha: #{color.adjust(#736eef, $alpha: -0.75)};

  // OTHER
  --border-radius-main: 6px;
  --border-radius-inner: 4px;
  --transition-duration: 0.2s;
  --cursor-comment: url('./commentCursor.svg') 16 16, pointer !important;
  --font-family: 'Inter', 'Helvetica Neue', arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
