.wrapper {
  position: relative;
  width: 100%;
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-primaryBackgroundColor);
  transition: opacity var(--transition-duration);
  transition: background-color var(--transition-duration);

  //&.active:before {
  //  content: '';
  //  position: absolute;
  //  background-color: var(--opacity-hover-editor);
  //  inset: 0;
  //}

  &.waiting {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.block {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid transparent;
  z-index: 0;

  &.active {
    border: 1px solid var(--color-primary-default);
    z-index: 1;
  }
}

.divider:before {
  content: '';
  position: absolute;
  border: 1px dashed var(--color-secondary-default);
  opacity: 0.3;
  inset: -2px -1px;
}

.icons {
  position: absolute;
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12.5px;
  transition: background-color var(--transition-duration);
  transform: translateX(-100%);
  cursor: pointer;
  color: var(--color-background-main);

  &.commentActive {
    background-color: var(--color-secondary-default);
  }

  &.task {
    top: 1px;
    left: -8px;
    background-color: var(--color-input-focus);
  }

  &.comment {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: var(--box-shadow-default);
    background-color: var(--color-background-main);
    cursor: pointer;
  }
}

.comments {
  cursor: var(--cursor-comment) !important;
}

.commentsDeep {
  * {
    cursor: var(--cursor-comment) !important;
  }
}
