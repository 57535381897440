.portalRoot {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  padding: 32px 56px;
  z-index: 100;

  &.fullScreen {
    padding: 0;
  }
}

.overlay {
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-dark);
  opacity: 0.9;
  inset: 0;
  //z-index: 100;
}

.root {
  position: relative;
  display: flex;
  width: 100%;
  max-width: calc(100vw - 112px);
  max-height: calc(100vh - 64px);
  flex-direction: column;
  border-radius: var(--border-radius-main);
  background: var(--color-background-main);
  z-index: 200;

  .body {
    display: flex;
    height: 100%;
    min-width: 560px;
    flex-grow: 1;
    flex-direction: column;
  }

  .bodyScroll {
    max-width: 100%;
    max-height: 100%;
    flex-grow: 1;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
  }

  .description {
    margin-top: 12px;
    font-size: 13px;
    line-height: 18px;
    color: var(--color-text-secondary);
  }

  .footer {
    display: flex;
    border-radius: 0 0 var(--border-radius-inner) var(--border-radius-inner);
    background: var(--color-background-main);

    &.start {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.end {
      justify-content: flex-end;
    }

    &.gapMini {
      > :not(:last-child) {
        margin-right: 4px;
      }
    }

    &.gapMedium {
      > :not(:last-child) {
        margin-right: 8px;
      }
    }

    &.gapLarge {
      > :not(:last-child) {
        margin-right: 16px;
      }
    }

    &.bordered {
      padding-top: 12px;
      padding-bottom: 12px;
      border-top: 1px solid var(--color-delimiter-main);
      z-index: 1;
    }
  }
}

.dialog {
  .root {
    max-width: 560px;
  }

  .header {
    padding: 32px 32px 16px;
  }

  .body {
    padding: 8px 32px;
  }

  .footer {
    padding: 16px 32px 32px;
  }

  .header + .footer {
    margin-top: 32px;
  }
}

.free {
  .root {
    //height: 100%;
  }
}

.tabs {
  /* stylelint-disable-next-line no-descending-specificity */
  .footer {
    padding: 12px 16px;
    border-top: 1px solid var(--color-delimiter-main);
  }
}

.crop {
  .header {
    padding: 32px 32px 16px;
  }
  /* stylelint-disable-next-line no-descending-specificity */
  .footer {
    padding: 20px 32px;
    border-top: 1px solid var(--color-delimiter-main);
  }
}

.close {
  position: absolute;
  display: flex;
  top: 0;
  right: -8px;
  flex-direction: column;
  align-items: center;
  font-size: 11px;
  transform: translate(100%, 0);
  cursor: pointer;
  pointer-events: none;
  color: var(--color-background-main);
}

.fullScreen {
  .root {
    max-width: 100%;
    max-height: 100%;
    padding: 0;
    border-radius: 0;
  }

  .close {
    top: 16px;
    right: 40px;
    cursor: pointer;
    pointer-events: all;
    z-index: 1001;
    color: var(--color-background-dark);
  }
}

.containerWrap {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
}
